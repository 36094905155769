import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'
import Img from 'components/Img'

import enhancer from './hooks'

const Hero = ({ onClickSidebar }) => {
  return (
    <section className="hero-home">
      <div className="container">
        <div className="hero-home__content">
          <div className="hero-home__text">
            <Typography
              as="h2"
              variant="headline3"
              className="hero-home__title"
              weight="medium">
              O benefício que <b>reinventa</b> o futuro financeiro <br/> do seu time
            </Typography>
            <Typography
              as="p"
              variant="body1"
              className="hero-home__description">
              A melhor solução de <b>previdência e saúde financeira</b> para
              seus colaboradores.
            </Typography>

            <div className="hero-home__button-wrapper">
              <Button
                label="Agende uma conversa"
                iconId="right-arrow"
                iconPosition="right"
                customClass="hero-home__button"
                onClick={onClickSidebar}
              />
            </div>
          </div>
        </div>
        <div className="hero-home__image">
          <Img
            className="hero-home__image-phone"
            src="/rebranding-hero-img3.png"
            alt="phone"
          />
        </div>
      </div>
    </section>
  )
}

export default enhancer(Hero)
